import React from 'react';
import { IGatsbyImageData, GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { BLUE, LIGHT_GREEN, PRIMARY_GREEN } from '../colors';

import './content-entry-styles.css';

const styles = {
  h4: {
    background: PRIMARY_GREEN,
    color: 'white',
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    paddingLeft: '20px',
    paddingRight: '20px',
    display: 'inline-block',
    marginBottom: 0,
    marginTop: 0,
  }
}

interface ContentEntryProps {
  image: string;
  title: string;
  contentText: string;
}

export default function ContentEntry(props: ContentEntryProps) {

  return (
    <div className="content-wrapper" style={{
      // width: '50%',
      display: 'flex',
      // borderLeft: `2px ${PRIMARY_GREEN} solid`
      // height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
    >

        <div><h4 style={styles.h4}>{props.title}</h4></div>
        <p>{props.contentText}</p>
        <img
          src={props.image}
          alt=""
          style={{
            width: '100%',
            objectFit: 'cover',
          }}
        />


    </div>
  );
}