import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
/** @jsx jsx */
import { jsx } from "theme-ui"
import './persoenliches-styles.css';


const styles = {
  li: {
    marginTop: 0,
    marginBottom: 20,
  },
}

export default function Persoenliches() {
  return (
    <div id="persoenliches-wrapper">
      <h1>Persönliches</h1>
      <div id="persoenliches-content" style={{
        display: 'flex',
        alignItems: 'stretch',
        columnGap: 40,
        flexWrap: 'wrap'
      }}>
        <div id="persoenliches-description" style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <ul>
          <li style={styles.li}>Jahrgang 1962, verheiratet, 2 erwachsene Kinder</li>
          <li style={styles.li}>aufgewachsen auf einem Bauernhof in Emsdetten / Westfalen</li>
          <li style={styles.li}>gelernter Diplom-Verwaltungswirt</li>
          <li style={styles.li}>seit 40 Jahren in der Kommunalverwaltung tätig, seit 30 Jahren in leitender Funktion</li>
          <li style={styles.li}>lebt seit 1995 in Belm</li>
          <li style={styles.li}>auch privat und in Vereinen für Belm engagiert</li>
          <li style={styles.li}>Hobbys: Sport, Fahrrad, Lesen, Fotografieren</li>
          </ul>
        </div>

        <div id="image-wrapper" style={{
          display: 'flex',
          background: 'whitesmoke',
          padding: '0.6rem',
          boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.27)',
        }}>
          <StaticImage
            loading={'eager'}
            src="../../static/persoenliches.png"
            alt=""
            style={{
              padding: '2rem',
              background: 'white'
            }}
          />
        </div>
      </div>


    </div>
  );
}