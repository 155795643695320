import React from 'react';
import { PREFIX } from '../../../constants';

import './contact-styles.css';

export default function Kontakt() {
  return (
    <div id="contact-wrapper">
      <h1>Kontakt</h1>
      <div id="contact">
        {/* <h2>Facebook</h2>
        <h2>E-Mail</h2> */}
        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flex: 1, gap: 40, alignContent: 'stretch', justifyItems: 'center', width: '100%' }}>
            <a href="mailto:info@viktor-hermeler.de" title="email" className="bg-blue-hermeler seen rounded-full p-3 mx-2 my-2 transform hover:scale-110 active:scale-90 transition-transform duration-300 ease-in-out">
            <img src="/email.svg" alt="email icon" style={{
              width: '100%'
            }} />

          </a>
          <div>
          <h3 style={{ marginBottom: 0 }}>Sie können mich per E-Mail unter</h3>
          <h3 style={{ marginTop: 0, marginBottom: 0}}>info@viktor-hermeler.de</h3>
          <h3 style={{ marginTop: 0}}>erreichen.</h3>
          </div>


          </div> */}
        <div className="contact-wrapper">
        <h3>Sie können mich per E-Mail unter <b style={{ fontStyle: 'italic' }}>info@viktor-hermeler.de</b> erreichen.</h3>

          <a href="mailto:info@viktor-hermeler.de" style={{ display: 'flex',  }}>
            <img src={`${PREFIX}/email.svg`} alt="email icon" style={{
              width: '100%'
            }} />
          </a>
        </div>


        {/* <a href="https://www.facebook.com/Viktor-Hermeler-375291082583300/" title="facebook" className="bg-blue-hermeler seen rounded-full p-3 mx-2 my-2 transform hover:scale-110 active:scale-90 transition-transform duration-300 ease-in-out" target="_blank" rel="noopener">
            <img style={{ width: '33%' }} src="/facebook.svg" alt="facebook icon" />
          </a> */}

        {/* </div> */}
      </div>
    </div>
  );
}