import React from 'react';
import ContentEntry from '../components/ContentEntry';
import { PREFIX } from '../constants';

export default function Inhalte() {
  return (
    <div>
      <h1>Inhalte</h1>
      <div id="entries" style={{
        display: 'flex',
        flexWrap: 'wrap',
        // flexDirection: 'column',
        alignItems: 'stretch',
        gap: 40,
      }}>
        <ContentEntry
          title="Menschen im Mittelpunkt"
          contentText="Die Bedürfnisse der Menschen prägen unsere Arbeit. Bürgerforen und Einwohnerversammlungen bleiben bewährte Beteiligungsformen für Dialog und Transparenz."
          image={`${PREFIX}/inhalte/menschen_im_mittelpunkt.png`}
        />

        <ContentEntry
          title="Klimaschutz"
          contentText="Klimagerechte und nachhaltige Gemeindeentwicklung gehören zu einer modernen Gesellschaft. Erneuerbare Energie muss bei künftiger baulicher Entwicklung Vorrang haben."
          image={`${PREFIX}/inhalte/klimaschutz.png`}
        />

        <ContentEntry
          title="Ehrenamt und Jugend"
          contentText="Unsere Gemeinde lebt von bürgerschaftlichem Engagement. Belm zeichnet sich durch die Vielfalt der Angebote von Vereinen, Verbänden und Kirchengemeinden aus. Die Gemeinde muss das bestmöglich unterstützen und sinnvoll ergänzen."
          image={`${PREFIX}/inhalte/ehrenamt.png`}
        />

        <ContentEntry
          title="Gemeindeleben"
          contentText={`Ins Zentrum der Gemeinde gehören Einkaufen, Wohnen und ein lebendiger Marktplatz als Treffpunkt zum Verweilen und für Veranstaltungen, wie Wochenmarkt und Kultursommer. Gastronomie, Einzelhandel und Gewerbe benötigen starkes kommunales Engagement.`}
          image={`${PREFIX}/inhalte/gemeindeleben.png`}
        />

        <ContentEntry
          title="Arbeitsplätze und Wirtschaftskraft"
          contentText="Neue Gewerbeflächen fördern die Entwicklung örtlicher Unternehmen und sind für die Ansiedlung neuer Firmen mit Arbeitsplätzen und Wirtschaftskraft wichtig. Mit der örtlichen Wirtschaft erfolgt ein regelmäßiger Austausch."
          image={`${PREFIX}/inhalte/arbeitsplätze_wirtschaftskraft.png`}
        />

        <ContentEntry
          title="Familie und Bildung"
          contentText="Gute Bildungsangebote und Rahmenbedingungen in Kita und Schule sind familienfreundlich und zukunftsorientiert. Die digitale Ausstattung wird weiter ausgebaut."
          image={`${PREFIX}/inhalte/familie_bildung.png`}
        />

        <ContentEntry
          title="Mobilität"
          contentText="Stärkung von Bus, Bahn und Rad: 10-Minuten-Takt nach Osnabrück sichern, 30-Minuten-Takt für Icker und Vehrte umsetzen, Bahnverbindung Vehrte-Osnabrück realisieren, Schnellradweg Belm-Osnabrück bauen, Schnellladestationen für E-Autos an verkehrswichtigen Punkten und an jedem Supermarkt."
          image={`${PREFIX}/inhalte/mobilitaet.png`}
        />

        <ContentEntry
          title="Geordnete Finanzen"
          contentText="Finanzielle Leistungsfähigkeit ist Rückgrat der kommunalen Selbstverwaltung und Grundlage gemeindlicher Handlungsfähigkeit."
          image={`${PREFIX}/inhalte/finanzen.png`}
        />
      </div>

    </div>
  );
}