import React, { useRef, useEffect, useState } from 'react';
import './aktuelles-css.css';
// import { FacebookProvider, Page } from 'react-facebook';


export default function Aktuelles() {
  const [maxWidth, setMaxWidth] = useState(-1);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current.offsetWidth && maxWidth === -1) {
      setMaxWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <h1 ref={ref}>Aktuelles</h1>
      {maxWidth > 0 && (
        <iframe
          src={`https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2F375291082583300&width=${maxWidth >= 500 ? 500 : maxWidth}&colorscheme=light&show_faces=true&stream=true&header=true&height=750&locale=de_DE`}
          scrolling="yes"
          style={{
            border: 'none', 
            overflow: 'hidden',
            height: 750,
            width: 500,
            float: 'left',
          }}
          id="facebook-iframe-vh"
          frameBorder="0"
        ></iframe>)}
    </div>
  );
}


{/*  */ }
